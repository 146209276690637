@import 'custom';
@import '~bootstrap/scss/bootstrap';

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
}

.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#root {
    padding-top: 46px;
}

body,
#root {
    min-height: 100vh;
    height: 0;
}

.spinner-border {
    border-width: 0.25rem;
}

.btn {
    box-shadow: none !important;
}
