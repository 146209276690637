@import 'variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@each $color, $value in $theme-colors {
    .btn.btn-link.#{$color} {
        color: $value;
        &:hover {
            color: darken($color: $value, $amount: 15%);
        }
    }
}

@each $color, $value in $colors {
    .text-#{$color} {
        color: $value !important;
    }
    .btn.btn-link.#{$color} {
        color: $value;
        &:hover {
            color: darken($color: $value, $amount: 15%);
        }
    }
    .btn.btn-#{$color} {
        @include button-variant($value, $value);
    }
}
